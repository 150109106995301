.container {
  min-height: 100vh;
}
.header {
  padding: 0;
  background-color: #11203F;
}
.header .wrapper {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  display: flex;
  align-items: center;
  font-size: 1rem;
}
.title span {
  color: #FFFFFF;
}
.logo {
  width: 56px;
  height: 56px;
  margin-right: 0.75rem;
}
.content {
  padding-top: 0;
  padding-bottom: 1.5rem;
}
.content .wrapper {
  padding-right: 1rem;
  padding-left: 1rem;
}

@media (min-width: 576px) {
  .title {
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) {
  .header .wrapper {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .content .wrapper {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

@media (min-width: 992px) {
  .header .wrapper {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .content .wrapper {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .header .wrapper {
    padding-right: 0;
    padding-left: 0;
    max-width: 1152px;
    margin: 0 auto;
  }
  .content .wrapper {
    padding-right: 0;
    padding-left: 0;
    max-width: 1152px;
    margin: 0 auto;
  }
}
