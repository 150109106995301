.container {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}
.background {
  background-image: url("../../images/welcome.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  flex: auto;
  min-height: 120px;
}
.aside {
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  text-align: center;
  background-color: #FFFFFF;
  position: relative;
}
.logo {
  width: 100px;
  height: 100px;
  margin-bottom: 0.5rem;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
  }
  .aside {
    width: 50%;
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .logo {
    width: 126px;
    height: 126px;
  }
}

@media (min-width: 992px) {
  .aside {
    width: 40%;
    padding-top: 2.5rem;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .content {
    max-width: 440px;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}