.container {
  padding: 1.5rem;
  text-align: center;
}

.glyph {
  display: block;
  width: 120px;
  height: auto;
  margin: 2.5rem auto;
}
.glyph svg {
  fill: rgba(0, 0, 0, 0.24);
}

@media (min-width: 992px) {
  .glyph {
    width: 160px;
    margin: 3rem auto;
  }
}