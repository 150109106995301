.option {
  display: flex;
  margin-bottom: 0.5rem;
}
.slider {
  display: flex;
  align-items: center;
  width: 120px;
  margin-right: 1rem;
  position: relative;
  top: -4px;
}