.container {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.icon {
  margin-left: 0.5rem;
  color: #52C40F;
}
.status {
  color: #52C40F;
}