.item {
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.content {
  text-align: center;
  min-height: 360px;
  display: flex;
  align-items: center;
}