.label {
  margin: 1rem 0;
}
.answer {
  margin-top: 1rem;
  pointer-events: none;
}
.draggable {
  margin-top: -1rem;
  margin-bottom: 0.75rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  cursor: ns-resize;
}
.normal, .editable {
  margin-bottom: 1.5rem;
}
.editable {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)
}
.footer {
  margin-top: 1.5rem;
  text-align: right;
  display: flex;
  justify-content: space-between;
}
.mandatory::after {
  content: '*';
  color: #FF4D4F;
  margin-left: 0.25rem;
}
.title {
  font-size: 1.25rem;
}
.title span {
  color: #11203F;
}
.title .description {
  font-size: 1rem;
}
.section {
  font-size: 1.25rem;
}
.section span {
  color: #961A42;
}
.error {
  color:#FF4D4F;
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 0.25rem;
}