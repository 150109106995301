.question {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
.radio, .checkbox {
  display: block;
  height: 2rem;
  line-height: 2rem;
}
.required::after {
  display: inline-block;
  content: '*';
  font-size: 0.875rem;
  position: relative;
  top: -4px;
  left: 4px;
  color: #FF4d4F;
  font-weight: bold;
}
.conditionalQuestion {
  font-size: 1.125rem;
  margin: 1rem 0;
}